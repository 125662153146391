<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        sort-by="userCode"
        :sort-desc="false"
        :items-per-page="5"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>User</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <!-- <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              @keyup.enter="searchClick"
            ></v-text-field> -->

            <v-text-field
              clearable
              hide-details
              v-model="userCode"
              label="User Code"
              @keyup.enter="searchClick"
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              clearable
              hide-details
              v-model="userFirstName"
              label="First Name"
              @keyup.enter="searchClick"
            ></v-text-field>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-select
                clearable
                hide-details
                v-model="userGroup"
                :items="userGroups"
                label="User Group"
            ></v-select>
            <!-- <v-divider class="mx-4" inset vertical></v-divider>
            <v-checkbox
              v-model="enabled"
              label="Enabled"
              hide-details
            ></v-checkbox> -->
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-spacer></v-spacer>
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <v-btn @click="newDocument" color="primary" dark class="mb-2">
              <v-icon left>add</v-icon>
              <span>New User</span>
            </v-btn>
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.userCode }}</td>
            <td>{{ item.userFirstName }}</td>
            <td>{{ item.userLastName }}</td>
            <td>{{ item.userType }}</td>
            <td>{{ item.userGroup }}</td>
            <td>{{ item.brcode }}</td>
            <td>{{ item.createdDatetime }}</td>
            <td>{{ item.updatedDatetime }}</td>
            <td>
              <v-checkbox readonly v-model="item.active"></v-checkbox>
            </td>
            <td>
              <v-icon class="mr-2" @click="editItem(item)">
                edit
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
  </div>
</template>

<script>
import api from "@/services/lambdaApi";

export default {
  name: "user",
  data() {
    return {
      loading: false,
      // search: "",
      userCode: "",
      userFirstName: "",
      userGroup: "",
      userGroups: ["IT", "CS", "Account", "Buyer", "Marketing", "Operation", "SuperUser"],
      mDataArray: [],
      headers: [
        { text: "User Code", value: "userCode" },
        { text: "First Name", value: "userFirstName" },
        { text: "Last Name", value: "userLastName" },
        { text: "User Type", value: "userType" },
        { text: "User Group", value: "userGroup" },
        { text: "Brcode", value: "brcode" },
        { text: "Create Datetime", value: "createdDatetime" },
        { text: "Update Datetime", value: "updatedDatetime" },
        {
          text: "Active",
          align: "left",
          // sortable: false,
          value: "active",
        },
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      this.loadData();
  },
  methods: {
    searchClick() {
      this.loadData();
    },
    editItem(item) {
      this.$router.push(`/user/edit/${item.userCode}`);
    },
    newDocument() {
      this.$router.push("/user/edit/0");
    },
    async loadData() {
      try {
        this.loading = true;
        let filter = { userCode: this.userCode, userFirstName: this.userFirstName, userGroup: this.userGroup }
        // console.log("--- filter ---", filter);
        let result = await api.getUserList(filter, this.userCode2);
        // console.log("--- data list ---", result.data);
        this.mDataArray = result.data;
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    userCode2() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    // userGroup() {
    //   return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    // },
    // userType() {
    //   return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    // },
    // userBrcode() {
    //   return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    // },
  }
};
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>