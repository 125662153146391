<template>
  <div>
    <v-container v-if="loading">
    <div class="text-center">
      <v-progress-circular indeterminate :size="80" :width="8" color="primary">
      </v-progress-circular>
    </div>
  </v-container>
  <v-container fluid v-else>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="mDataArray"
        sort-by="deliveryTime"
        :sort-desc="false"
        :items-per-page="15"
      >
        <!-- table top section -->
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Assigned Pickers</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <!-- <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              @keyup.enter="searchClick"
            ></v-text-field> -->

            <!-- <v-text-field
              hide-details
              v-model="branchId"
              label="Brcode"
              @keyup.enter="searchClick"
            ></v-text-field> -->

            <v-select 
              clearable
              hide-details 
              v-model="branchSelect"
              :items="stores" 
              item-text="storeName" 
              item-value="storeCode" 
              label="Branch"
              :disabled="userBrcode != ''"
              return-object
            ></v-select>

            <v-divider class="mx-4" inset vertical></v-divider>

            <v-select 
              clearable
              hide-details 
              v-model="statusSelect"
              :items="mStatus" 
              item-text="statusName" 
              item-value="isPaid" 
              label="Select"
              return-object
            ></v-select>

            <v-divider class="mx-4" inset vertical></v-divider>

            <!-- <v-dialog
              ref="dialog1"
              v-model="modal1"
              :return-value.sync="date1"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  v-model="date1"
                  label="From Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date1"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal1 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog1.save(date1)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog> -->

            <!-- <v-dialog
              ref="dialog2"
              v-model="modal2"
              :return-value.sync="date2"
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  hide-details
                  v-model="date2"
                  label="To Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date2"
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="modal2 = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.dialog2.save(date2)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog> -->

            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <v-text-field
              clearable
              hide-details
              v-model="orderId"
              label="OrderId"
              @keyup.enter="searchClick"
            ></v-text-field>

            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon class="mr-2" @click="searchClick">
              search
            </v-icon>
            <v-spacer></v-spacer>
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <!-- <v-btn @click="newDocument" color="primary" dark class="mb-2">
              <v-icon left>add</v-icon>
              <span>New Document</span>
            </v-btn> -->
          </v-toolbar>
        </template>

        <!-- table tr section -->
        <template v-slot:item="{ item }">
          <tr>
            <td :class="getRowClass(item)">
              <v-icon class="mr-2" color="green darken-2" v-if="item.pickingStatus == 'Finish'">
                done
              </v-icon>

              <v-icon class="mr-2" color="red darken-2" @click="showPicker(item)" v-else-if="(userType != 'Picker' && userType != 'User') && (item.picker == '' || item.pickingStatus == 'Assigned')">
                person
              </v-icon>
              <v-icon class="mr-2" color="gray darken-2" v-else-if="userType != 'Picker' && userType != 'User'">
                person
              </v-icon>

              <v-icon class="mr-2" color="red darken-2" @click="bookedOrder(item)" v-else-if="(userType == 'Picker' || userType == 'User') && (item.picker == '')">
                star
              </v-icon>
              <v-icon class="mr-2" color="gray darken-2" v-else="userType == 'Picker' || userType == 'User'">
                star
              </v-icon>

              <!-- <v-icon class="mr-2" color="blue darken-2" @click="printReport(item)" v-if="userType != 'Picker' && userType != 'User'">
                print
              </v-icon> -->

            </td>
            <td :class="getRowClass(item)">{{ item.orderId }}</td>
            <td :class="getRowClass(item)">{{ item.branchName }}</td>
            <td :class="getRowClass(item)">{{ item.shippingType }}</td>
            <td :class="getRowClass(item)">{{ item.deliveryTime }}</td>
            <td :class="getRowClass(item)">{{ item.picker }}</td>
            <td :class="getRowClass(item)">{{ item.pickingStartTime }}</td>
            <td :class="getRowClass(item)">{{ item.pickingEndTime }}</td>
            <td :class="getRowClass(item)">{{ item.pickingStatus }}</td>
            <td :class="getRowClass(item)">
              <v-checkbox readonly v-model="item.isPaid"></v-checkbox>
            </td>
          </tr>
        </template>
      </v-data-table>

      <template>
        <v-row justify="center">
          <v-dialog
            v-model="dialog"
            max-width="500px"
          >
            <v-card>
              <v-card-title>Select Pickers</v-card-title>
              <v-divider></v-divider>
                <template>
                  <div class="row mx-0 pa-5">
                    <div class="col-12 table_pickers">
                      <table class="table table-striped" width="100%">
                        <thead class="thead-dark" align-center>
                          <tr>
                            <th scope="col" style="text-align: left">User Code</th>
                            <th scope="col" style="text-align: left">First Name</th>
                            <th scope="col" style="text-align: left">Last Name</th>
                            <th scope="col" style="text-align: left">Br Code</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in pickers" :key="item.userCode" @click="selectPicker(item)">
                            <td>{{ item.userCode }}</td>
                            <td>{{ item.userFirstName }}</td>
                            <td>{{ item.userLastName }}</td>
                            <td>{{ item.brcode }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  </template>
                <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-card>
  </v-container>
  </div>
</template>

<script>
import api from "@/services/lambdaApi";

export default {
  name: "assignpicker",
  data() {
    return {
      loading: false,
      dialog: false,
      // search: "",
      branchId: "",
      orderId: "",
      orderItem: null,
      stores:[],
      branchSelect: null,
      statusSelect: null,
      // date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // modal1: false,
      // modal2: false,
      mDataArray: [],
      mPickers: [],
      mStatus: [
        // { "statusName": "ALL", "isPaid": 1 },
        { "statusName": "Assign แล้ว", "isPaid": 3 },
        { "statusName": "ยังไม่ได้ Assign", "isPaid": 4 },
      ],
      headers: [
        {
          text: "Action",
          align: "left",
          sortable: false,
          value: "action",
        },
        { text: "OrderId", value: "orderId" },
        { text: "Branch", value: "branchId" },
        // { text: "FirstName", value: "firstName" },
        // { text: "LastName", value: "lastName" },
        { text: "ShippingType", value: "shippingType" },
        { text: "Delivery", value: "deliveryTime" },
        // { text: "GrandTotal", value: "grandTotal" },
        { text: "Picker", value: "picker" },
        { text: "StartTime", value: "pickingStartTime" },
        { text: "EndTime", value: "pickingEndTime" },
        { text: "Status", value: "pickingStatus" },
        {
          text: "IsPaid",
          align: "left",
          // sortable: false,
          value: "isPaid",
        },
      ],
    };
  },
  // components: {
  // },
  async mounted() {
      try {
        this.loading = true;

        this.statusSelect = this.mStatus[1];

        let filter = { fileName: "online_stores.json" };
        let result = await api.getS3Json(filter);
        this.stores = result.data;

        let filter2 = { userCode: "", userFirstName: "", userGroup: "Operation", active: 1 }
        let pickers = await api.getUserList(filter2, this.userCode);
        this.mPickers = pickers.data;

        if (this.userBrcode != "") {
          let mStore = this.stores.find(d => d.storeCode === this.userBrcode);
          if (mStore) {
            this.branchSelect = mStore;
          }
        }

        await this.loadData();
        
        setInterval(this.searchClick, 300000);

        // } catch (error) {
      } finally {
        this.loading = false;
      }
  },
  methods: {
    getRowClass(item) {
      let s = item?.shippingType ?? "";
      if (s.toLowerCase().indexOf("express") == -1)
        return ""
      else
        return "row_express";
    },
    printReport() {
      alert('Printed');
    },
    async searchClick() {
      try {
        this.loading = true;
        await this.loadData();
        // } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async bookedOrder(item) {
      if (confirm(`Confirm select order ${item.orderId}?`)) {
        try {
            let data = {
              "action": "assignPickers",
              "orderId": item.orderId,
              "userCode": this.userCode,
              "picking": {
                "picker": this.userCode
              }
            }
            console.log('-- request assignPickers --', JSON.stringify(data));
            let result = await api.updatePicking(data);
            console.log('-- result assignPickers --', result.data ?? "");
            
            let picker = result.data["picker"] ?? "";
            if (picker != "") {
              let mItem = this.mDataArray.find(d => d.orderId === item.orderId);
              if (mItem) {
                mItem["picker"] = picker;
                mItem["pickingStatus"] = result.data["pickingStatus"] ?? "";
                let index = this.mDataArray.indexOf(mItem);
                this.$set(this.mDataArray, index, mItem);
              }
            }
          } catch (error) {
          } finally {
        }
      }
    },
    async setPicker(item) {
      try {
          let data = {
            "action": "assignPickers",
            "orderId": this.orderItem.orderId,
            "userCode": "",
            "picking": {
              "picker": item.userCode
            }
          }
          console.log('-- request assignPickers --', JSON.stringify(data));
          let result = await api.updatePicking(data);
          console.log('-- result assignPickers --', result.data ?? "");
          
          let picker = result.data["picker"] ?? "";
          if (picker != "") {
            let mItem = this.mDataArray.find(d => d.orderId === this.orderItem.orderId);
            if (mItem) {
              mItem["picker"] = picker;
              mItem["pickingStatus"] = result.data["pickingStatus"] ?? "";
              let index = this.mDataArray.indexOf(mItem);
              this.$set(this.mDataArray, index, mItem);
            }
          }
        } catch (error) {
        } finally {
        }
    },
    async selectPicker(item) {
      // alert(item.userCode);
      if (this.orderItem != null) {
        await this.setPicker(item);
        this.orderItem = null;
      }
      this.dialog = false;
    },
    showPicker(item) {
      this.orderItem = item;
      this.dialog = true;
      // alert('Assign Pickers');
      // this.$router.push(`/picking/edit/${item.orderId}`);
    },
    // newDocument() {
    //   this.$router.push("/picking/edit/0");
    // },
    async loadData() {
      // try {
      //   this.loading = true;
        if (this.branchSelect != null)
          this.branchId = this.branchSelect.storeCode;
        else
          this.branchId = "";
        // let filter = { branchId: this.branchId, isPaid: 1 }
        // let filter = { orderId: this.orderId, branchId: this.branchId, startDate: this.date1, endDate: this.date2, isPaid: 1 }
        var isPaid = 1;
        if (this.statusSelect != null) {
          isPaid = parseInt(this.statusSelect.isPaid);
        }
        let filter = { orderId: this.orderId, branchId: this.branchId, isPaid: isPaid }
        // console.log("--- filter ---", filter);
        let result = await api.getOrderList(filter);
        // console.log("--- order list ---", result.data);
        this.mDataArray = result.data;
        // } catch (error) {
      // } finally {
      //   this.loading = false;
      // }
    },
  },
  computed: {
    userCode() {
      return this.$store.getters["userInfo"]["userCode"]?.toString() ?? "";
    },
    userGroup() {
      return this.$store.getters["userInfo"]["userGroup"]?.toString().toLowerCase().trim() ?? "";
    },
    userType() {
      return this.$store.getters["userInfo"]["userType"]?.toString() ?? "";
    },
    userBrcode() {
      return this.$store.getters["userInfo"]["brcode"]?.toString() ?? "";
    },
    pickers() {
      if (this.mPickers == null || this.orderItem == null)
        return []
      else {
        return this.mPickers.filter(o => o.brcode == this.orderItem.branchId)
      }
    },
  }
};
</script>

<style scoped>
table {
  border-spacing: 0 0.5rem;
  font-size: small;
}
.table_pickers {
   height: 300px;
   overflow: auto;
}
.v-progress-circular {
  margin: 1rem;
}
v-data-table table {
  border-spacing: 0 0.5rem;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.row_express {
  background-color: #FC0FC0;
  color: #ffffff;
}
</style>